exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-business-pricing-js": () => import("./../../../src/pages/business/pricing.js" /* webpackChunkName: "component---src-pages-business-pricing-js" */),
  "component---src-pages-business-projects-js": () => import("./../../../src/pages/business/projects.js" /* webpackChunkName: "component---src-pages-business-projects-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-goget-recruit-js": () => import("./../../../src/pages/goget_recruit.js" /* webpackChunkName: "component---src-pages-goget-recruit-js" */),
  "component---src-pages-gogetters-js": () => import("./../../../src/pages/gogetters.js" /* webpackChunkName: "component---src-pages-gogetters-js" */),
  "component---src-pages-homelife-js": () => import("./../../../src/pages/homelife.js" /* webpackChunkName: "component---src-pages-homelife-js" */),
  "component---src-pages-homelife-pricing-js": () => import("./../../../src/pages/homelife/pricing.js" /* webpackChunkName: "component---src-pages-homelife-pricing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-hourly-gig-index-js": () => import("./../../../src/pages/jobs/hourly-gig/index.js" /* webpackChunkName: "component---src-pages-jobs-hourly-gig-index-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-program-bina-kerjaya-js": () => import("./../../../src/pages/program_bina_kerjaya.js" /* webpackChunkName: "component---src-pages-program-bina-kerjaya-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms_conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-all-blogs-js": () => import("./../../../src/templates/all-blogs.js" /* webpackChunkName: "component---src-templates-all-blogs-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-career-page-js": () => import("./../../../src/templates/career-page.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-jobs-helper-page-js": () => import("./../../../src/templates/jobs-helper-page.js" /* webpackChunkName: "component---src-templates-jobs-helper-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-poster-helper-page-js": () => import("./../../../src/templates/poster-helper-page.js" /* webpackChunkName: "component---src-templates-poster-helper-page-js" */),
  "component---src-templates-recruit-page-js": () => import("./../../../src/templates/recruit-page.js" /* webpackChunkName: "component---src-templates-recruit-page-js" */)
}

