import React from 'react';
import './src/components/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return <>{element}</>;
};

const scrollTo = id => () => {
  const el = document.querySelector(id);
  if (el)
    return window.scrollTo({ top: el.offsetTop - 100, behavior: 'smooth' });
  return false;
};

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 100);
  }
};
